import React, { useState, useEffect } from "react";
import { convertUrlsToCdnAndImgKit } from "./convertURLToImg";
import { captureException } from "@sentry/nextjs";
import PropTypes from "prop-types";

const imageKitLoader = ({ src }) => {
    if (!src || src === "undefined") return src;
    const convertedUrl = convertUrlsToCdnAndImgKit({ url: src, type: "IMG" });
    return `${convertedUrl}?f-auto`;
};

const CustomImage = (props) => {
    const {
        src,
        alt,
        retryLimit = 2,
        fallback,
        NativeImgProps = {},
        ...restProps
    } = props;

    // State initialization
    const [imageSrc, setImageSrc] = useState(() => {
        const loadedSrc = imageKitLoader({ src });
        return loadedSrc;
    });

    const [currentRetry, setCurrentRetry] = useState(0);
    const [isError, setIsError] = useState(false);

    // Effect to update the image source when the `src` prop changes
    useEffect(() => {
        const loadedSrc = imageKitLoader({ src });
        setImageSrc(loadedSrc);
        setCurrentRetry(0); // Reset retry count on source change
        setIsError(false); // Reset error state on source change
    }, [src]);

    const handleError = (e) => {
        const currentUrl = window.location.href;
        const errorDetails = {
            error: e,
            currentPage: currentUrl,
            imageUrl: src,
            kind: "Image Load Error: Imagekit Parsing error",
        };

        captureException(
            new Error("Image Load Error:: Imagekit Parsing error"),
            {
                extra: {
                    errorDetails,
                    disable_replay: true,
                },
                tags: { kind: "Image Load Error: Imagekit Parsing error" },
            },
        );

        if (currentRetry < retryLimit) {
            setImageSrc(src); // Reattempt loading the original source
            setCurrentRetry(currentRetry + 1);
        } else {
            setIsError(true);
        }
    };

    return (
        <img
            {...NativeImgProps}
            src={imageSrc}
            alt={alt}
            onError={handleError}
            style={{
                display: isError ? "none" : "block",
                ...NativeImgProps.style,
                ...restProps.style,
            }}
            className={`${restProps.className || ""}`}
            {...restProps}
        />
    );
};

CustomImage.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    retryLimit: PropTypes.number,
    fallback: PropTypes.string,
    NativeImgProps: PropTypes.object,
};

export default CustomImage;
