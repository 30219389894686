import deepCopy from "@utils/deepCopy";
import { rmReverseDuplicates } from "@utils/helpers";
import set from "lodash/set";

class FigmaStates {
    figmaUpdatedJson = {};
    figmaUpdatedJsonIds = {};
    tappableIds = {};
    subscribeOnes = {
        submit: false,
        timeout: false,
    };
    attemptComponent = null;
    parent_variables = [];
    figmaNonUpdatedJson = {};
    graphOutput = null;
    showComponents = [];
    disableComponents = [];
    isSubjectiveEvaluation = false;
    subjectiveEvaluationCallback = null;
    showAudioToast = false;
    all_variables = [];
    isDisabled = false;
    isSubmitted = false;
    isGame = false;

    constructor() {
        this.prevSubscribeOnes = deepCopy(this.subscribeOnes);
        this.attemptComponent = null;
        this.parent_variables = [];
        this.all_variables = [];
    }

    getJson(index) {
        return this.figmaUpdatedJson[index];
    }

    setFirstJson(newJson, index) {
        // const json = JSON.parse(JSON.stringify(convertCircularToNonCircular(newJson)));
        this.figmaUpdatedJson[index] = newJson;
    }

    setJson(json, index) {
        if (this.isSubjectiveEvaluation && this.subjectiveEvaluationCallback) {
            this.subjectiveEvaluationCallback(json);
        }
        this.figmaUpdatedJson[index] = json;
    }

    setSubjectiveEvaluation(val, callback) {
        this.isSubjectiveEvaluation = val;
        this.subjectiveEvaluationCallback = callback;
    }

    setJsonPathForIds(index, id, path) {
        this.figmaUpdatedJsonIds[index] = {
            ...this.figmaUpdatedJsonIds[index],
            [id]: path,
        };
    }

    getJsonPathForIds(index, id) {
        return this.figmaUpdatedJsonIds[index]?.[id];
    }

    setTappableIds(index, id, data) {
        this.tappableIds[index] = {
            ...this.tappableIds[index],
            [id]: {
                ...this.tappableIds[index]?.[id],
                [data.type]: { ...data },
            },
        };
    }

    getTappableIds(index, id) {
        return this.tappableIds[index]?.[id];
    }

    updateParentVariables(parentVariables) {
        this.parent_variables = parentVariables;
    }

    getParentVariables() {
        return this.parent_variables;
    }

    getComponentVariables() {
        return this.all_variables;
    }

    setNonUpdatedJson(json, index) {
        const updatedJson =
            index == "question_audios" ? rmReverseDuplicates(json) : json;
        this.figmaNonUpdatedJson[index] = deepCopy(updatedJson);
    }

    resetNonUpdatedJson() {
        Object.keys((i) => (this.figmaNonUpdatedJson[i] = []));
    }

    getNonUpdatedJson(index) {
        return deepCopy(this.figmaNonUpdatedJson[index]);
    }

    updateValue(key, path, value) {
        const json = this.figmaUpdatedJson[key];
        set(json, path, value);
        this.figmaUpdatedJson[key] = json;
    }
    insertVariable(name, value) {
        const index = this.all_variables.findIndex((v) => v.name === name);
        if (index !== -1) {
            this.all_variables[index].value = value;
        } else {
            this.all_variables.push({ name, value });
        }
    }
    updateIsGame(isGame) {
        this.isGame = isGame;
    }

    reset() {
        this.figmaUpdatedJson = {};
        this.subscribeOnes = deepCopy(this.prevSubscribeOnes);
        this.attemptComponent = null;
        this.parent_variables = [];
        this.isSubjectiveEvaluation = false;
        this.subjectiveEvaluationCallback = null;
        this.showAudioToast = false;
        this.all_variables = [];
        this.isDisabled = false;
        this.isSubmitted = false;
        this.isGame = false;
        // this.figmaNonUpdatedJson = {};
    }
}

export const FigmaJsonStates = new FigmaStates();
// FigmaJsonState.
// export default FigmaState;
